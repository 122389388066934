
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { TextField } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import * as moment from 'moment';
// import AgroTourDeclaration from 'assets/declarationfiles/agrotourdeclaration.pdf';
import * as service from '../../services/service';

function NFLSRegistration() {
    const [state, setState] = useState(
        {
            emailid: '', mobileno: '', applicantname: '', address: '', institutename: '', instituteaddress: '',
            documentverifiedstatus: '', registrationCertificateFile: '', profileFile: '', participantDetailsFile: '', auditedStatementFile: '',
            preMediaPlanFile: '', purposeFile: '', proposalDetailsFile: '', artistDetailsFile: '', permissionFile: '',
            securityMeasuresFile: '', usefulSociallyFile: '', deliverablesFile: '', usefulForTourismFile: '',
            tourismObjectFile: '', videoFile: '', programname: '', programaddress: '', programperiod: ''
        });

    const [regionId, setRegionId] = useState('');
    const [regionData, setRegionData] = useState([]);
    const [districtId, setDistrictId] = useState('');
    const [districtData, setDistrictData] = useState([]);
    const [applicantType, setApplicantType] = useState('');
    const [registrationDate, setRegistrationDate] = useState(new Date());
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const applicantTypeData = [{ label: "Ltd", value: "Ltd" }, { label: "Pvt Ltd", value: "Pvt Ltd" },
    { label: "Partnership", value: "Partnership" }, { label: "NGO", value: "NGO" }, { label: "Proprietor", value: "Proprietor" },
    { label: "Local Government Bodies", value: "Local Government Bodies" }, { label: "Other", value: "Other" }];

    const [registrationCertificateFile, setRegistrationCertificateFile] = useState('');
    const [profileFile, setProfileFile] = useState('');
    const [participantDetailsFile, setParticipantDetailsFile] = useState('');
    const [auditedStatementFile, setAuditedStatementFile] = useState('');
    const [preMediaPlanFile, setPreMediaPlanFile] = useState('');
    const [purposeFile, setPurposeFile] = useState('');
    const [proposalDetailsFile, setProposalDetailsFile] = useState('');
    const [artistDetailsFile, setArtistDetailsFile] = useState('');
    const [permissionFile, setPermissionFile] = useState('');
    const [securityMeasuresFile, setSecurityMeasuresFile] = useState('');
    const [usefulSociallyFile, setUsefulSociallyFile] = useState('');
    const [deliverablesFile, setDeliverablesFile] = useState('');
    const [usefulForTourismFile, setUsefulForTourismFile] = useState('');
    const [tourismObjectFile, setTourismObjectFile] = useState('');
    const [videoFile, setVideoFile] = useState('');

    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const navigate = useNavigate();

    const getRegions = (id) => {
        fetch(
            service.GET_REGION_BY_STATE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    StateId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.regionId, label: item.region }));
                setRegionData(data2);
            });
    };

    const getDistricts = (id) => {
        fetch(
            service.GET_DISTRICT_BY_REGION_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    RegionId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.districtId, label: item.district }));
                setDistrictData(data2);
            });
    };

    useEffect(() => {
        getRegions(1);
        if (window.sessionStorage.getItem('userData') != null) {
            setState({ ...state, emailid: JSON.parse(window.sessionStorage.getItem('userData')).emailId });
        }
    }, []);

    function getExtension(filename) {
        return filename.split('.').pop()
    }

    const validate = () => {
        let isValid = true;
        const errs = {};
        // const maxSize = 150; // in KB
        const maxSize = 5120; // in KB = 5MB
        if (!state.emailid) {
            isValid = false;
            errs[".emailid"] = "Please enter Email Id";
        }
        if (!state.mobileno) {
            isValid = false;
            errs[".mobileno"] = "Please enter Mobile No.";
        }
        if (!state.applicantname) {
            isValid = false;
            errs[".applicantname"] = "Please enter Name";
        }
        if (!applicantType) {
            isValid = false;
            errs[".applicantType"] = "Please select Applicant Type";
        }
        if (!state.programname) {
            isValid = false;
            errs[".programname"] = "Please enter Program Name";
        }
        if (!state.programaddress) {
            isValid = false;
            errs[".programaddress"] = "Please enter Program Address";
        }
        if (!state.programperiod) {
            isValid = false;
            errs[".programperiod"] = "Please enter Program Period";
        }
        if (!state.address) {
            isValid = false;
            errs[".address"] = "Please enter Address";
        }
        if (!regionId) {
            isValid = false;
            errs[".region"] = "Please select Region";
        }
        if (!districtId) {
            isValid = false;
            errs[".district"] = "Please select District";
        }

        if (!registrationCertificateFile) {
            isValid = false;
            errs[".registrationCertificateFile"] = "Please upload file";
        }
        if (!profileFile) {
            isValid = false;
            errs[".profileFile"] = "Please upload file";
        }
        if (!participantDetailsFile) {
            isValid = false;
            errs[".participantDetailsFile"] = "Please upload file";
        }
        if (!auditedStatementFile) {
            isValid = false;
            errs[".auditedStatementFile"] = "Please upload file";
        }
        if (!preMediaPlanFile) {
            isValid = false;
            errs[".preMediaPlanFile"] = "Please upload file";
        }
        if (!purposeFile) {
            isValid = false;
            errs[".purposeFile"] = "Please upload file";
        }
        if (!proposalDetailsFile) {
            isValid = false;
            errs[".proposalDetailsFile"] = "Please upload file";
        }
        if (!artistDetailsFile) {
            isValid = false;
            errs[".artistDetailsFile"] = "Please upload file";
        }
        if (!permissionFile) {
            isValid = false;
            errs[".permissionFile"] = "Please upload file";
        }
        if (!securityMeasuresFile) {
            isValid = false;
            errs[".securityMeasuresFile"] = "Please upload file";
        }
        if (!usefulSociallyFile) {
            isValid = false;
            errs[".usefulSociallyFile"] = "Please upload file";
        }
        if (!deliverablesFile) {
            isValid = false;
            errs[".deliverablesFile"] = "Please upload file";
        }
        if (!usefulForTourismFile) {
            isValid = false;
            errs[".usefulForTourismFile"] = "Please upload file";
        }
        if (!tourismObjectFile) {
            isValid = false;
            errs[".tourismObjectFile"] = "Please upload file";
        }
        if (!videoFile) {
            isValid = false;
            errs[".videoFile"] = "Please upload file";
        }

        if (registrationCertificateFile && getExtension(registrationCertificateFile.name).toLowerCase() !== 'pdf'
            && getExtension(registrationCertificateFile.name).toLowerCase() !== 'jpg'
            && getExtension(registrationCertificateFile.name).toLowerCase() !== 'jpeg'
            && getExtension(registrationCertificateFile.name).toLowerCase() !== 'png'
            && getExtension(registrationCertificateFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".registrationCertificateFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (profileFile && getExtension(profileFile.name).toLowerCase() !== 'pdf'
            && getExtension(profileFile.name).toLowerCase() !== 'jpg'
            && getExtension(profileFile.name).toLowerCase() !== 'jpeg'
            && getExtension(profileFile.name).toLowerCase() !== 'png'
            && getExtension(profileFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".profileFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (participantDetailsFile && getExtension(participantDetailsFile.name).toLowerCase() !== 'pdf'
            && getExtension(participantDetailsFile.name).toLowerCase() !== 'jpg'
            && getExtension(participantDetailsFile.name).toLowerCase() !== 'jpeg'
            && getExtension(participantDetailsFile.name).toLowerCase() !== 'png'
            && getExtension(participantDetailsFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".participantDetailsFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (auditedStatementFile && getExtension(auditedStatementFile.name).toLowerCase() !== 'pdf'
            && getExtension(auditedStatementFile.name).toLowerCase() !== 'jpg'
            && getExtension(auditedStatementFile.name).toLowerCase() !== 'jpeg'
            && getExtension(auditedStatementFile.name).toLowerCase() !== 'png'
            && getExtension(auditedStatementFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".auditedStatementFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (preMediaPlanFile && getExtension(preMediaPlanFile.name).toLowerCase() !== 'pdf'
            && getExtension(preMediaPlanFile.name).toLowerCase() !== 'jpg'
            && getExtension(preMediaPlanFile.name).toLowerCase() !== 'jpeg'
            && getExtension(preMediaPlanFile.name).toLowerCase() !== 'png'
            && getExtension(preMediaPlanFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".preMediaPlanFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (purposeFile && getExtension(purposeFile.name).toLowerCase() !== 'pdf'
            && getExtension(purposeFile.name).toLowerCase() !== 'jpg'
            && getExtension(purposeFile.name).toLowerCase() !== 'jpeg'
            && getExtension(purposeFile.name).toLowerCase() !== 'png'
            && getExtension(purposeFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".purposeFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (proposalDetailsFile && getExtension(proposalDetailsFile.name).toLowerCase() !== 'pdf'
            && getExtension(proposalDetailsFile.name).toLowerCase() !== 'jpg'
            && getExtension(proposalDetailsFile.name).toLowerCase() !== 'jpeg'
            && getExtension(proposalDetailsFile.name).toLowerCase() !== 'png'
            && getExtension(proposalDetailsFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".proposalDetailsFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (artistDetailsFile && getExtension(artistDetailsFile.name).toLowerCase() !== 'pdf'
            && getExtension(artistDetailsFile.name).toLowerCase() !== 'jpg'
            && getExtension(artistDetailsFile.name).toLowerCase() !== 'jpeg'
            && getExtension(artistDetailsFile.name).toLowerCase() !== 'png'
            && getExtension(artistDetailsFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".artistDetailsFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (permissionFile && getExtension(permissionFile.name).toLowerCase() !== 'pdf'
            && getExtension(permissionFile.name).toLowerCase() !== 'jpg'
            && getExtension(permissionFile.name).toLowerCase() !== 'jpeg'
            && getExtension(permissionFile.name).toLowerCase() !== 'png'
            && getExtension(permissionFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".permissionFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (securityMeasuresFile && getExtension(securityMeasuresFile.name).toLowerCase() !== 'pdf'
            && getExtension(securityMeasuresFile.name).toLowerCase() !== 'jpg'
            && getExtension(securityMeasuresFile.name).toLowerCase() !== 'jpeg'
            && getExtension(securityMeasuresFile.name).toLowerCase() !== 'png'
            && getExtension(securityMeasuresFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".securityMeasuresFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }

        if (usefulSociallyFile && getExtension(usefulSociallyFile.name).toLowerCase() !== 'pdf'
            && getExtension(usefulSociallyFile.name).toLowerCase() !== 'jpg'
            && getExtension(usefulSociallyFile.name).toLowerCase() !== 'jpeg'
            && getExtension(usefulSociallyFile.name).toLowerCase() !== 'png'
            && getExtension(usefulSociallyFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".usefulSociallyFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }

        if (deliverablesFile && getExtension(deliverablesFile.name).toLowerCase() !== 'pdf'
            && getExtension(deliverablesFile.name).toLowerCase() !== 'jpg'
            && getExtension(deliverablesFile.name).toLowerCase() !== 'jpeg'
            && getExtension(deliverablesFile.name).toLowerCase() !== 'png'
            && getExtension(deliverablesFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".deliverablesFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }

        if (usefulForTourismFile && getExtension(usefulForTourismFile.name).toLowerCase() !== 'pdf'
            && getExtension(usefulForTourismFile.name).toLowerCase() !== 'jpg'
            && getExtension(usefulForTourismFile.name).toLowerCase() !== 'jpeg'
            && getExtension(usefulForTourismFile.name).toLowerCase() !== 'png'
            && getExtension(usefulForTourismFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".usefulForTourismFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }

        if (tourismObjectFile && getExtension(tourismObjectFile.name).toLowerCase() !== 'pdf'
            && getExtension(tourismObjectFile.name).toLowerCase() !== 'jpg'
            && getExtension(tourismObjectFile.name).toLowerCase() !== 'jpeg'
            && getExtension(tourismObjectFile.name).toLowerCase() !== 'png'
            && getExtension(tourismObjectFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".tourismObjectFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }

        if (videoFile && getExtension(videoFile.name).toLowerCase() !== 'pdf'
            && getExtension(videoFile.name).toLowerCase() !== 'jpg'
            && getExtension(videoFile.name).toLowerCase() !== 'jpeg'
            && getExtension(videoFile.name).toLowerCase() !== 'png'
            && getExtension(videoFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".videoFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (registrationCertificateFile && registrationCertificateFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".registrationCertificateFile"] = "File size limit 5 MB";
        }
        if (profileFile && profileFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".profileFile"] = "File size limit 5 MB";
        }
        if (participantDetailsFile && participantDetailsFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".participantDetailsFile"] = "File size limit 5 MB";
        }
        if (auditedStatementFile && auditedStatementFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".auditedStatementFile"] = "File size limit 5 MB";
        }
        if (preMediaPlanFile && preMediaPlanFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".preMediaPlanFile"] = "File size limit 5 MB";
        }
        if (purposeFile && purposeFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".purposeFile"] = "File size limit 5 MB";
        }
        if (proposalDetailsFile && proposalDetailsFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".proposalDetailsFile"] = "File size limit 5 MB";
        }
        if (artistDetailsFile && artistDetailsFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".artistDetailsFile"] = "File size limit 5 MB";
        }
        if (permissionFile && permissionFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".permissionFile"] = "File size limit 5 MB";
        }
        if (securityMeasuresFile && securityMeasuresFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".securityMeasuresFile"] = "File size limit 5 MB";
        }
        if (usefulSociallyFile && usefulSociallyFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".usefulSociallyFile"] = "File size limit 5 MB";
        }
        if (deliverablesFile && deliverablesFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".deliverablesFile"] = "File size limit 5 MB";
        }
        if (usefulForTourismFile && usefulForTourismFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".usefulForTourismFile"] = "File size limit 5 MB";
        }
        if (tourismObjectFile && tourismObjectFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".tourismObjectFile"] = "File size limit 5 MB";
        }
        if (videoFile && videoFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".videoFile"] = "File size limit 5 MB";
        }
        setErrors(errs);
        return isValid;
    }

    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const regionChangeHandler = (ev) => {
        setRegionId(ev);
        setDistrictId(null);
        getDistricts(ev.value);
    }
    const districtChangeHandler = (ev) => {
        setDistrictId(ev);
    }
    const applicantTypeChangeHandler = (ev) => {
        setApplicantType(ev);
    }

    const backHandler = () => {
        navigate('/authentication/sign-in/basic');
    };

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            const formData = new FormData();
            formData.append("NFLSRegistrationId", 0);
            formData.append("EmailId", state.emailid);
            formData.append("MobileNo", state.mobileno);
            formData.append("ApplicantName", state.applicantname);
            formData.append("ApplicantType", applicantType.value);
            formData.append("Address", state.address);
            formData.append("RegionId", regionId.value);
            formData.append("DistrictId", districtId.value);
            formData.append("InstituteName", state.institutename);
            formData.append("InstituteAddress", state.instituteaddress);
            formData.append("ProgramName", state.programname);
            formData.append("ProgramAddress", state.programaddress);
            formData.append("ProgramPeriod", state.programperiod);
            formData.append("RegistrationDate", (new Date(registrationDate)).toUTCString());

            formData.append("registrationCertificateFileRemarks", state.registrationCertificateFile);
            formData.append("profileFileRemarks", state.profileFile);
            formData.append("participantDetailsFileRemarks", state.participantDetailsFile);
            formData.append("auditedStatementFileRemarks", state.auditedStatementFile);
            formData.append("preMediaPlanFileRemarks", state.preMediaPlanFile);
            formData.append("purposeFileRemarks", state.purposeFile);
            formData.append("proposalDetailsFileRemarks", state.proposalDetailsFile);
            formData.append("artistDetailsFileRemarks", state.artistDetailsFile);
            formData.append("permissionFileRemarks", state.permissionFile);
            formData.append("securityMeasuresFileRemarks", state.securityMeasuresFile);
            formData.append("usefulSociallyFileRemarks", state.usefulSociallyFile);
            formData.append("deliverablesFileRemarks", state.deliverablesFile);
            formData.append("usefulForTourismFileRemarks", state.usefulForTourismFile);
            formData.append("tourismObjectFileRemarks", state.tourismObjectFile);
            formData.append("videoFileRemarks", state.videoFile);

            formData.append("RegistrationCertificateFile", registrationCertificateFile);
            formData.append("ProfileFile", profileFile);
            formData.append("ParticipantDetailsFile", participantDetailsFile);
            formData.append("AuditedStatementFile", auditedStatementFile);
            formData.append("PreMediaPlanFile", preMediaPlanFile);
            formData.append("PurposeFile", purposeFile);
            formData.append("ProposalDetailsFile", proposalDetailsFile);
            formData.append("ArtistDetailsFile", artistDetailsFile);
            formData.append("PermissionFile", permissionFile);
            formData.append("SecurityMeasuresFile", securityMeasuresFile);
            formData.append("UsefulSociallyFile", usefulSociallyFile);
            formData.append("DeliverablesFile", deliverablesFile);
            formData.append("UsefulForTourismFile", usefulForTourismFile);
            formData.append("TourismObjectFile", tourismObjectFile);
            formData.append("VideoFile", videoFile);

            fetch(
                service.ADD_NFLS_REGISTRATION,
                {
                    method: 'POST',
                    body: formData
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert("Congratulations! Your application has been submitted successfully.");
                        navigate('/nflsregistrationsuccess');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    return (
        <div style={{ width: '100%' }}>
            {/* <DashboardNavbar /> */}
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Non Financial Logo Services Registration / विना आर्थिक सहाय्य प्रस्ताव नोंदणी
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                <TableContainer component={Paper} style={{ width: '80%', alignSelf: 'center' }}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan="2" align="left" style={{ fontWeight: 'bold' }}>
                                                    * Required
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ width: '45%' }}>
                                                    <MDInput label="Email Id" fullWidth value={state.emailid || ''}
                                                        name="emailid"
                                                        onChange={changeHandler}
                                                        id="emailid"
                                                        required
                                                        disabled={window.sessionStorage.getItem('userData')}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".emailid"]}</div>
                                                </TableCell>
                                                <TableCell style={{ width: '35%' }}>
                                                    <MDInput label="Mobile No." fullWidth value={state.mobileno || ''}
                                                        name="mobileno"
                                                        onChange={changeHandler}
                                                        id="mobileno"
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".mobileno"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Full Name of Applicant"
                                                        fullWidth value={state.applicantname || ''}
                                                        name="applicantname"
                                                        onChange={changeHandler}
                                                        id="applicantname"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".applicantname"]}</div>
                                                </TableCell>
                                                <TableCell style={{ width: '35%' }}>
                                                    Registration Date
                                                    <DatePicker
                                                        id="registrationDate"
                                                        selected={registrationDate}
                                                        onChange={date => setRegistrationDate(date)}
                                                        dateFormat="MM/dd/yyyy"
                                                        tabIndex={0}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".registrationDate"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Select
                                                        options={applicantTypeData}
                                                        name="applicantType"
                                                        value={applicantType}
                                                        onChange={applicantTypeChangeHandler}
                                                        placeholder="Select Applicant Type"
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".applicantType"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Full Address of Applicant" fullWidth value={state.address || ''}
                                                        name="address"
                                                        onChange={changeHandler}
                                                        id="address"
                                                        multiline
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".address"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Institute Name"
                                                        fullWidth value={state.institutename || ''}
                                                        name="institutename"
                                                        onChange={changeHandler}
                                                        id="institutename"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".institutename"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Institute Address"
                                                        fullWidth value={state.instituteaddress || ''}
                                                        name="instituteaddress"
                                                        onChange={changeHandler}
                                                        id="instituteaddress"
                                                        multiline />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".instituteaddress"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Select
                                                        options={regionData}
                                                        name="region"
                                                        value={regionId}
                                                        onChange={regionChangeHandler}
                                                        placeholder="Select Region"
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".region"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <Select className="basic-single"
                                                        options={districtData}
                                                        name="district"
                                                        value={districtId}
                                                        onChange={districtChangeHandler}
                                                        isSearchable
                                                        placeholder="Select District"
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".district"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Program Name"
                                                        fullWidth value={state.programname || ''}
                                                        name="programname"
                                                        onChange={changeHandler}
                                                        id="programname"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".programname"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Program Address"
                                                        fullWidth value={state.programaddress || ''}
                                                        name="programaddress"
                                                        onChange={changeHandler}
                                                        id="programaddress"
                                                        multiline />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".programaddress"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                            <TableCell>
                                                    <MDInput label="Program Period"
                                                        fullWidth value={state.programperiod || ''}
                                                        name="programperiod"
                                                        onChange={changeHandler}
                                                        id="programperiod"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".programperiod"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2" style={{ fontWeight: 'bold' }}>
                                                    <Table>
                                                        <TableRow>
                                                            <TableCell colSpan="3" align="left" style={{ fontSize: '14px', color: 'red', fontWeight: 'bold' }}>
                                                                विना आर्थिक सहाय्य प्रस्ताव नोंदणी करताना नमूद मुद्द्यांसाठी आवश्यक माहिती देणे बंधनकारक आहे. यातील एखादा मुद्दा लागू नसेल तर त्यासाठी स्व घोषणापत्र जोडणे अनिवार्य आहे.
                                                                <br />
                                                                As part of the non financial logo services application process, applicants must furnish detailed information addressing each of the outlined points.
                                                                <br />
                                                                If any point is inapplicable, a Self-Declaration certificate must be appended to the application.
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell width="10%" style={{ fontWeight: 'bold' }}>1</TableCell>
                                                            <TableCell width="70%" style={{ fontWeight: 'bold' }}>
                                                                <span style={{ color: 'red' }}>*</span>संस्थेचे नोंदणी प्रमाणपत्र व तारीख / Organisation  Registration Certificate and Date (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="registrationCertificateFile"
                                                                    type="file"
                                                                    onChange={ev => setRegistrationCertificateFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                    required
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".registrationCertificateFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.registrationCertificateFile || ''}
                                                                    name="registrationCertificateFile"
                                                                    onChange={changeHandler}
                                                                    id="registrationCertificateFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".registrationCertificateFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>2</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                <span style={{ color: 'red' }}>*</span> संस्थेचा कार्यानुभव ,संस्थेच्या मालकाची माहिती/Experience of Organisation (Profile) (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="profileFile"
                                                                    type="file"
                                                                    onChange={ev => setProfileFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                    required
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".profileFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.profileFile || ''}
                                                                    name="profileFile"
                                                                    onChange={changeHandler}
                                                                    id="profileFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".profileFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>3</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                <span style={{ color: 'red' }}>*</span> सदर महोत्सवात/कार्यक्रमात सहभागी होणाऱ्या सह-प्रायोजक ,सहभागी संस्था यांचा तपशील/Details of co-sponsors /organization participating in the in this festival /event and copies of work order (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="participantDetailsFile"
                                                                    type="file"
                                                                    onChange={ev => setParticipantDetailsFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".participantDetailsFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.participantDetailsFile || ''}
                                                                    name="participantDetailsFile"
                                                                    onChange={changeHandler}
                                                                    id="participantDetailsFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".participantDetailsFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>4</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                <span style={{ color: 'red' }}>*</span> अन्य माध्यमातून प्रायोजकत्व उभारण्यात येणार आहे त्याचा तपशील/Audited statement of Sponsorship generated from other sources (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="auditedStatementFile"
                                                                    type="file"
                                                                    onChange={ev => setAuditedStatementFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".auditedStatementFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.auditedStatementFile || ''}
                                                                    name="auditedStatementFile"
                                                                    onChange={changeHandler}
                                                                    id="auditedStatementFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".auditedStatementFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>5</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                <span style={{ color: 'red' }}>*</span> संस्थेच्या महोत्सव/कार्यक्रमचा (पूर्व –प्रसिद्धी )मिडिया प्लान आणि संबधित कागदपत्रे/Festival / Event Pre Media Plan with Supporting document (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="preMediaPlanFile"
                                                                    type="file"
                                                                    onChange={ev => setPreMediaPlanFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                    required
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".preMediaPlanFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.preMediaPlanFile || ''}
                                                                    name="preMediaPlanFile"
                                                                    onChange={changeHandler}
                                                                    id="preMediaPlanFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".preMediaPlanFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>6</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                <span style={{ color: 'red' }}>*</span> सदर महोत्सव/कार्यक्रम पर्यटन संवर्धन/वृद्धी साठी आहे का? असल्यास कार्यक्रमाचे ध्येय, उदिष्ट आणि काय साध्य/निष्पन्न  करावयाचे आहे त्याची माहिती (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="purposeFile"
                                                                    type="file"
                                                                    onChange={ev => setPurposeFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                    required
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".purposeFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.purposeFile || ''}
                                                                    name="purposeFile"
                                                                    onChange={changeHandler}
                                                                    id="purposeFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".purposeFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>7</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                <span style={{ color: 'red' }}>*</span>  सदर प्रस्तावासोबत महोत्सवाचा सविस्तर तपशिल उदा.महोत्सवाचे नाव, स्थळ, कालावधी, कार्यक्रमाची रुपरेषा, स्वरूप इ. माहिती/Detailed description of the festival along with this proposal: eg name, place, period of the festival/ Event  outline of the program, format, as well as financial  expectations from DOT (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="proposalDetailsFile"
                                                                    type="file"
                                                                    onChange={ev => setProposalDetailsFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                    required
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".proposalDetailsFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.proposalDetailsFile || ''}
                                                                    name="proposalDetailsFile"
                                                                    onChange={changeHandler}
                                                                    id="proposalDetailsFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".proposalDetailsFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>8</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                <span style={{ color: 'red' }}>*</span> महोत्सवात जर सांस्कृतिक कार्यक्रमाचा समावेश असल्यास कलाकाराचा तपशील (नाव, कला, नृत्य प्रकार ,संपर्क इ.)/Artist details (name, art, dance type, contact, etc.), if included in a cultural program (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="artistDetailsFile"
                                                                    type="file"
                                                                    onChange={ev => setArtistDetailsFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                    required
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".artistDetailsFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.artistDetailsFile || ''}
                                                                    name="artistDetailsFile"
                                                                    onChange={changeHandler}
                                                                    id="artistDetailsFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".artistDetailsFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>9</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                <span style={{ color: 'red' }}>*</span>  आयोजकांनी कार्यक्रमासाठी लागणाऱ्या विविध परवानग्या  (शासकीय) घेतल्या आहेत  कि नाही (असल्यास संबधित कागदपत्रे)/Whether or not the organizers have taken various permission (government) for the program (if relevant documents) (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="permissionFile"
                                                                    type="file"
                                                                    onChange={ev => setPermissionFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                    required
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".permissionFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.permissionFile || ''}
                                                                    name="permissionFile"
                                                                    onChange={changeHandler}
                                                                    id="permissionFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".permissionFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>10</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                <span style={{ color: 'red' }}>*</span>  कार्यक्रमादरम्यान सुरक्षा विषयक घेण्यात येणारी काळजी/Details for security measures taken by organizer during festival /Event (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="securityMeasuresFile"
                                                                    type="file"
                                                                    onChange={ev => setSecurityMeasuresFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".securityMeasuresFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.securityMeasuresFile || ''}
                                                                    name="securityMeasuresFile"
                                                                    onChange={changeHandler}
                                                                    id="securityMeasuresFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".securityMeasuresFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>11</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                <span style={{ color: 'red' }}>*</span> सदर महोत्सव/ कार्यक्रम पर्यावरण/ सामाजिकदृष्ट्या कश्याप्रकारे उपयोगी ठरणार आहे./ How will this festival/event be useful environmentally/socially? (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="usefulSociallyFile"
                                                                    type="file"
                                                                    onChange={ev => setUsefulSociallyFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                    required
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".usefulSociallyFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.usefulSociallyFile || ''}
                                                                    name="usefulSociallyFile"
                                                                    onChange={changeHandler}
                                                                    id="usefulSociallyFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".usefulSociallyFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>12</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                <span style={{ color: 'red' }}>*</span> सदर महोत्सव/ कार्यक्रमात सहभागी होण्याकरिता आयोजकानी देऊ केलेल्या सोयी सुविधा (Derivebles) जसे महाराष्ट्र पर्यटनाला दालन/Deliverables offered by  organizer to Maharashtra Tourism for participating in festival/Event (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="deliverablesFile"
                                                                    type="file"
                                                                    onChange={ev => setDeliverablesFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".deliverablesFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.deliverablesFile || ''}
                                                                    name="deliverablesFile"
                                                                    onChange={changeHandler}
                                                                    id="deliverablesFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".deliverablesFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>13</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                <span style={{ color: 'red' }}>*</span> महाराष्ट्र पर्यटनाला या कार्यक्रमामूळे काय फायदे होणार आहेत?/How will this festival/event be useful for Maharashtra Tourism? (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="usefulForTourismFile"
                                                                    type="file"
                                                                    onChange={ev => setUsefulForTourismFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".usefulForTourismFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.usefulForTourismFile || ''}
                                                                    name="usefulForTourismFile"
                                                                    onChange={changeHandler}
                                                                    id="usefulForTourismFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".usefulForTourismFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>14</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                <span style={{ color: 'red' }}>*</span> पर्यटन संबंधीत कोणते घटक या कार्यक्रमाव्दारे जोडलेले आहेत? (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="tourismObjectFile"
                                                                    type="file"
                                                                    onChange={ev => setTourismObjectFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".tourismObjectFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.tourismObjectFile || ''}
                                                                    name="tourismObjectFile"
                                                                    onChange={changeHandler}
                                                                    id="tourismObjectFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".tourismObjectFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>15</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                <span style={{ color: 'red' }}>*</span>  कार्यक्रमाचे व्हिडियो, रिल्स, फोटोज संचालनालयाला देण्यात येतील का?/Will the organizers submit the videos/reels/photos of the event to the Directorate of Tourism? (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="videoFile"
                                                                    type="file"
                                                                    onChange={ev => setVideoFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".videoFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.videoFile || ''}
                                                                    name="videoFile"
                                                                    onChange={changeHandler}
                                                                    id="videoFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".videoFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                    </Table>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Table style={{ width: '20%' }}>
                                    <TableRow>
                                        <TableCell>
                                            <MDBox mt={4} mb={1} textAlign="center">
                                                {loading ? <Oval
                                                    heigth="20"
                                                    width="20"
                                                    color='grey'
                                                    ariaLabel='loading'
                                                /> :
                                                    <MDButton color="info" onClick={submitHandler}>
                                                        SAVE
                                                    </MDButton>
                                                }
                                                {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                            </MDBox>
                                        </TableCell>
                                        <TableCell>
                                            <MDBox mt={4} mb={1} textAlign="center">
                                                <MDButton color="secondary" onClick={backHandler}>
                                                    Back
                                                </MDButton>
                                            </MDBox>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>

            <Footer />
        </div>
    );
}

export default NFLSRegistration;