/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// import Icon from "@mui/material/Icon";
// Authentication layout components
import { TextField } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as moment from 'moment';
import { Oval } from 'react-loader-spinner';
import * as service from '../../services/service';

function ViewSponsorship() {
    // const [state, setState] = useState({ certificatefilename: '', certificatefilepath: '' });
    const [data, setData] = useState([{}]);
    const [documentData, setDocuemntData] = useState([{}]);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState('');
    const [errors, setErrors] = useState({});

    const navigate = useNavigate();
    const params = useParams();

    const getRegistrationById = () => {
        setLoading(true);
        // const errs = {};        
        if (params.id > 0) {
            fetch(
                service.GET_SPONSORSHIP_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        SponsorshipId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setData(result);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        }
        setLoading(false);
    };

    const getDocumentsById = () => {
        setLoading(true);
        // const errs = {};        
        if (params.id > 0) {
            fetch(
                service.GET_SPONSORSHIP_DOCUMENT_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        SponsorshipId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result[0].message.messageType === 'Success') {
                        setDocuemntData(result);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        }
        setLoading(false);
    };


    // const getCertificateById = () => {
    //     fetch(
    //         service.GET_AGRO_CERTIFICATE_BY_ID,
    //         {
    //             method: 'POST',
    //             headers: {
    //                 'Accept': 'application/json',
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify({
    //                 AgroRegistrationId: params.id,
    //             })
    //         })
    //         .then(res => res.json())
    //         .then(result => {
    //             if (result.message.messageType === 'Success') {
    //                 setState({
    //                     ...state, certificatefilename: result.fileName, certificatefilepath: result.filePath,
    //                 });
    //             }
    //         });
    //     setLoading(false);
    // };

    useEffect(() => {
        setLoading(true);
        getRegistrationById();
        getDocumentsById();
        // getCertificateById();
        setLoading(false);
    }, []);

    const backHandler = () => {
        navigate('/authentication/sign-in/basic');
    };

    const handleDownload = (fileName, filePath) => {
        setLoading(true);
        if (fileName !== 'NoFile' && filePath !== 'NoFile') {
            const formData = new FormData();
            formData.append("FileName", fileName);
            formData.append("FilePath", filePath);
            fetch(
                service.DOWNLOAD_FILE,
                {
                    method: 'POST',
                    body: formData
                })
                .then((response) => response.blob())
                .then((blob) => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        fileName,
                    );
                    // Append to html link element page
                    document.body.appendChild(link);
                    // Start download
                    link.click();
                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                });
        }
        setLoading(false);
    };

    function getExtension(filename) {
        return filename.split('.').pop()
    }
    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!file) {
            isValid = false;
            errs[".file"] = "Please upload file";
        }
        if (file && getExtension(file.name).toLowerCase() !== 'pdf'
            && getExtension(file.name).toLowerCase() !== 'jpg'
            && getExtension(file.name).toLowerCase() !== 'jpeg'
            && getExtension(file.name).toLowerCase() !== 'png'
            && getExtension(file.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".file"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        setErrors(errs);
        return isValid;
    }

    const submitHandler = (e, docName, docId) => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            const formData = new FormData();
            formData.append("SponsorshipId", params.id);
            formData.append("SponsorshipDocumentId", docId);
            formData.append(docName, file);

            fetch(
                service.UPDATE_SPONSORSHIP_DOCUMENT_BY_ID,
                {
                    method: 'POST',
                    body: formData
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        window.location.reload();
                        // navigate('/agrotourregistrationsuccess');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    return (
        <div style={{ width: '100%' }}>
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                                mx={2}
                                mt={-3}
                                p={2}
                                mb={1}
                                textAlign="center"
                            >
                                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                                    Sponsorship Registration Details
                                </MDTypography>
                            </MDBox>
                            {loading ? <Oval
                                heigth="100"
                                width="100"
                                color='grey'
                                ariaLabel='loading'
                            /> :
                                <MDBox pt={4} pb={3} px={3}>
                                    <MDBox component="form" role="form">
                                        {/* <div style={{ fontSize: '12px', color: 'red' }}>{errors}</div> */}
                                        <Card style={{ alignItems: 'center' }}>
                                            <MDBox pt={4} pb={3} px={3} style={{ width: '100%' }}>
                                                <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center' }}>
                                                    <Table id="tblReceipt" style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell colSpan={2}>
                                                                    <Table style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Email Id :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.emailId}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Mobile No. :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.mobileNo}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Full Name of Applicant :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.applicantName}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Applicant Type :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.applicantType}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Full Address of Applicant :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.address}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Registration Date :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {moment(data.registrationDate).format("DD/MM/YYYY")}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Institute Name :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.instituteName}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Institute Address :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.instituteAddress}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Region :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.region}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                District :
                                                                            </TableCell>
                                                                            <TableCell colSpan="3" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.district}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Event Date :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {moment(data.eventDate).format("DD/MM/YYYY")}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Sponsorship For :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.sponsorshipFor}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Deliverable 1 :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.deliverable1}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Deliverable 2 :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.deliverable2}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Deliverable 3 :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.deliverable3}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Deliverable 4 :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.deliverable4}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Deliverable 5 :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.deliverable5}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Deliverable 6 :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.deliverable6}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Deliverable 7 :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.deliverable7}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Deliverable 8 :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.deliverable8}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Deliverable 9 :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.deliverable9}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Deliverable 10 :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.deliverable10}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Assistane Director`s Status :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.status}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Joint Director`s Status
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.jointDirectorStatus}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Director`s Status :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.directorStatus}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Remarks By Admin
                                                                            </TableCell>
                                                                            <TableCell colSpan="6" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.remarks}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </Table>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                    <Table>
                                                        <TableRow>
                                                            <TableCell colSpan="7" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Details
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow style={{ backgroundColor: 'lightsalmon' }}>
                                                            <TableCell style={{ width: '5%', fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Sr.No.
                                                            </TableCell>
                                                            <TableCell style={{ width: '20%', fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Document Name
                                                            </TableCell>
                                                            <TableCell style={{ width: '20%', fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                File Name
                                                            </TableCell>
                                                            <TableCell style={{ width: '20%', fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Remarks (Submitted by Applicant)
                                                            </TableCell>
                                                            <TableCell style={{ width: '20%', fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Status
                                                            </TableCell>
                                                            <TableCell style={{ width: '20%', fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Verification Date
                                                            </TableCell>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Upload
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableBody>
                                                            {documentData.map((row, index) => (
                                                                <TableRow
                                                                    key={row.sponsorshipDocumentId}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{index + 1}</TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        <MDButton onClick={() => handleDownload(row.fileName, row.filePath)}>{row.documentName}</MDButton>
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.fileName}</TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.remarks}</TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.status}</TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.verificationDate ? moment(row.verificationDate).format("DD/MM/YYYY") : ''}</TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {row.status === "Rejected" ?
                                                                            <div>
                                                                                <TextField
                                                                                    id="file"
                                                                                    type="file"
                                                                                    onChange={ev => setFile(ev.target.files[0])}
                                                                                    style={{ width: 300 }}
                                                                                    required
                                                                                />
                                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".file"]}</div>
                                                                                <MDButton color="info" onClick={e => submitHandler(e, row.documentName, row.sponsorshipDocumentId)}>
                                                                                    Save
                                                                                </MDButton>
                                                                            </div>
                                                                            : null}

                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                    <br />
                                                    <Table>
                                                        <TableRow style={{ backgroundColor: 'lightsalmon' }}>
                                                            <TableCell colSpan="7" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Other Details
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ width: '20%', fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Presentation Date
                                                            </TableCell>
                                                            <TableCell style={{ width: '20%', fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                {data.presentationDate ? moment(data.presentationDate).format('DD/MM/YYYY') : null}
                                                            </TableCell>
                                                            <TableCell style={{ width: '20%', fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Presentation Status
                                                            </TableCell>
                                                            <TableCell colSpan="3" style={{ width: '20%', fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                {data.presentationStatus}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Proposal File
                                                            </TableCell>
                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                {data.proposalFileName ?
                                                                    <MDButton onClick={() => handleDownload(data.proposalFileName, data.proposalFilePath)}>{data.proposalFileName}</MDButton>
                                                                    : null}
                                                            </TableCell>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Proposal Date
                                                            </TableCell>
                                                            <TableCell colSpan="3" width="15%" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                {data.proposalDate ? moment(data.proposalDate).format('DD/MM/YYYY') : null}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Approval File
                                                            </TableCell>
                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                {data.approvalFileName ?
                                                                    <MDButton onClick={() => handleDownload(data.approvalFileName, data.approvalFilePath)}>{data.approvalFileName}</MDButton>
                                                                    : null}
                                                            </TableCell>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Approval Date
                                                            </TableCell>
                                                            <TableCell colSpan="3" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                {data.approvalDate ? moment(data.approvalDate).format('DD/MM/YYYY') : null}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell width="15%"  style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Work Order File
                                                            </TableCell>
                                                            <TableCell width="15%"  style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                {data.workOrderFileName ?
                                                                    <MDButton onClick={() => handleDownload(data.workOrderFileName, data.workOrderFilePath)}>{data.workOrderFileName}</MDButton>
                                                                    : null}
                                                            </TableCell>
                                                            <TableCell width="15%"  style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Work Order Date
                                                            </TableCell>
                                                            <TableCell width="15%"  style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                {data.workOrderDate ? moment(data.workOrderDate).format('DD/MM/YYYY') : null}
                                                            </TableCell>
                                                            <TableCell width="15%"  style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Work Order Number
                                                            </TableCell>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                {data.workOrderNo}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Email Sent Date
                                                            </TableCell>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                {data.emailSent ? moment(data.emailSentDate).format('DD/MM/YYYY') : ''}
                                                            </TableCell>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Sponsorship Amount
                                                            </TableCell>
                                                            <TableCell colSpan="3" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                {data.sponsorshipAmount}
                                                            </TableCell>
                                                        </TableRow>
                                                    </Table>
                                                </TableContainer>
                                            </MDBox>
                                            <MDBox mt={4} mb={1} textAlign="center">
                                                <Table>
                                                    <TableRow>
                                                        <TableCell>
                                                            <MDButton color="secondary" onClick={backHandler}>
                                                                Log Out
                                                            </MDButton>
                                                        </TableCell>
                                                    </TableRow>
                                                </Table>
                                            </MDBox>
                                        </Card>
                                    </MDBox>
                                </MDBox>

                            }
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </div>
    );
}

export default ViewSponsorship;
